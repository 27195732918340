import React, { useState } from 'react';
import RecoveryPlan from './RecoveryPlan';
import ActivityTime from './ActivityTime';
import ExerciseDifficultyFeedback from './ExerciseDifficultyFeedback';
import WeeklyProgressChart from './WeeklyProgressChart';
import './PatientProgressOverview.css'; // Import the corresponding stylesheet

const PatientProgressOverview = ({ patient }) => {
    if (!patient) {
        return <div>Loading patient data...</div>;
    }

    // Destructure the necessary data from patient's progressData
    const { exercises, difficultyFeedback, activityTimes, labels, data } = patient.progressData;

    // Prepare the feedback with day labels for ExerciseDifficultyFeedback component
    const feedbackWithDays = labels.map((day, index) => {
        return {
            day: day,
            difficulty: difficultyFeedback[index]
        };
    });

    return (
        <div className="patient-progress-overview">
            
            <RecoveryPlan plan={{ title: 'Current Recovery Plan', exercises }} />
            <ExerciseDifficultyFeedback feedback={feedbackWithDays} />
            <ActivityTime times={activityTimes} />
            <WeeklyProgressChart progressData={{ labels, data }} />

            <textarea></textarea>
        </div>
    );
};

export default PatientProgressOverview;

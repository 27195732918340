import React from 'react';
import './RecoveryPlan.css'; // Import the stylesheet

const RecoveryPlan = ({ plan }) => {
    if (!plan || !plan.exercises) {
        return <div>Loading...</div>;
    }

    return (
        <div className="recovery-plan">
            <h3 className="plan-title">{plan.title.toUpperCase()}</h3>
            <div className="plan-content">
                <div className="buttons-container">
                    <button className="plan-button">UPLOAD VIDEO</button>
                    <button className="plan-button">UPDATE EXERCISE</button>
                    <button className="plan-button">MAKE SCHEDULE</button>
                </div>
                <ul className="exercises-list">
                    {plan.exercises.map((exercise, index) => (
                        <li key={index}>{exercise}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RecoveryPlan;

import React, { useState } from 'react';
import './PatientList.css';
import PatientProgressOverview from './PatientProgressOverview'; // Import the new component

const PatientList = () => {
    const [selectedPatient, setSelectedPatient] = useState(null);
    const patients = [
        { 
            id: 1324, 
            name: 'John Wick',
            plan: 'Ankle',
            progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
                '1. Ankle circles',
                '2. Ankle pumps',
                '3. Deep calf stretch',
                '4. Calf stretch',
                '5. Heel raises',
                '6. Calf stretch',
                '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
                currentWeek: '228/300',
                overall: '700/1000'
            },
        }
        },
    { 
        id: 1574, 
        name: 'Matt Davis', 
        plan: 'Leg',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [40, 20, 50, 60, 10, 20, 58],
            exercises: [
            '1. Leg press',
            '2. Leg extension',
            '3. Hack squats',
            '4. front squats',
            '5. Leg curl',
            '6. Split squats',
            '7. Romanian deadlift',
            ],
            difficultyFeedback: ['medium', 'medium', 'hard', 'hard', 'easy', 'hard', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '310/300',
            overall: '900/1000'
            },
        }
    },
    { 
        id: 1352, 
        name: 'Clark Ket', 
        plan: 'Shoulder ',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id: 1300, 
        name: 'Roland Franco', 
        plan: 'Knee',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id: 1747, 
        name: 'Joy Bing', 
        plan: 'Elbow',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1042, 
        name: 'Johnson Li', 
        plan: 'Ankle',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1077, 
        name: 'Happy Bird', 
        plan: 'Head',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1234, 
        name: 'Angry Ben', 
        plan: 'Foot',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1666, 
        name: 'Sad Otsa', 
        plan: 'Finger',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1451, 
        name: 'Hot Dog', 
        plan: 'Elbow',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1907, 
        name: 'Fried Rice', 
        plan: 'Leg',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1342, 
        name: 'Carrot Cake', 
        plan: 'Shoulder',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1999, 
        name: 'Ice Cream', 
        plan: 'Ankle',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
            '2. Ankle pumps',
            '3. Deep calf stretch',
            '4. Calf stretch',
            '5. Heel raises',
            '6. Calf stretch',
            '7. Heel dips and raises',
            ],
            difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
            activityTimes: {
            currentWeek: '228/300',
            overall: '700/1000'
            },
        }
    },
    { 
        id:1213, 
        name: 'Chicken Leg', 
        plan: 'Chest',
        progressData: {
            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            data: [30, 45, 20, 60, 0, 50, 30],
            exercises: [
            '1. Ankle circles',
                '2. Ankle pumps',
                '3. Deep calf stretch',
                '4. Calf stretch',
                '5. Heel raises',
                '6. Calf stretch',
                '7. Heel dips and raises',
                ],
                difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
                activityTimes: {
                currentWeek: '228/300',
                overall: '700/1000'
                },
            }
        },
        { 
            id:1567, 
            name: 'Beef Shank', 
            plan: 'Arm',
            progressData: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                data: [30, 45, 20, 60, 0, 50, 30],
                exercises: [
                '1. Ankle circles',
                '2. Ankle pumps',
                '3. Deep calf stretch',
                '4. Calf stretch',
                '5. Heel raises',
                '6. Calf stretch',
                '7. Heel dips and raises',
                ],
                difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
                activityTimes: {
                currentWeek: '228/300',
                overall: '700/1000'
                },
            }
        },
        { 
            id:1678, 
            name: 'Pork Ball', 
            plan: 'Chest',
            progressData: {
                labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                data: [30, 45, 20, 60, 0, 50, 30],
                exercises: [
                '1. Ankle circles',
                '2. Ankle pumps',
                '3. Deep calf stretch',
                '4. Calf stretch',
                '5. Heel raises',
                '6. Calf stretch',
                '7. Heel dips and raises',
                ],
                difficultyFeedback: ['hard', 'medium', 'easy', 'medium', 'hard', 'easy', 'medium'], // Ratings for each day of the week
                activityTimes: {
                currentWeek: '228/300',
                overall: '700/1000'
                },
            }
        },
    ];

    const handlePatientClick = patient => {
        setSelectedPatient(patient);
    };

    return (
        <div className="container">
        <div className="patient-list">
            {patients.map(patient => (
            <div 
                key={patient.id} 
                className={`patient-item ${selectedPatient && selectedPatient.id === patient.id ? 'selected' : ''}`}
                onClick={() => handlePatientClick(patient)}
            >
                User: {patient.id}<br />
                Name: {patient.name}<br />
                Plan: {patient.plan}
            </div>
            ))}
        </div>
        {selectedPatient && <PatientProgressOverview key={selectedPatient.id} patient={selectedPatient} />}
        </div>
    );
};

export default PatientList;

import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Needed for Chart.js v3+

const WeeklyProgressChart = ({ progressData }) => {
    if (!progressData) {
        return <div>Loading chart...</div>; // Render a loading text or a spinner
    }

    // Prepare the data for the chart
    const data = {
        labels: progressData.labels, // e.g., ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        datasets: [
            {
                label: 'Minutes of Exercise',
                data: progressData.data, // e.g., [30, 45, 20, 60, 0, 50, 30]
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };
    
    // Prepare the options for the chart
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        title: {
            display: true,
            text: 'Weekly Exercise Progress'
        }
    },
    scales: {        
        y: {
            beginAtZero: true
        }
    }
};

    return (
    <div className="weekly-progress-chart">
        <h3 className="title">WEEKLY PROGRESS</h3>
        <Line data={data} options={options} />
    </div>
    );
};

export default WeeklyProgressChart;

import React from 'react';
import './ExerciseDifficultyFeedback.css'; // Ensure the CSS file is imported

// Assuming `feedback` is an array of objects with `day` and `difficulty` properties
const ExerciseDifficultyFeedback = ({ feedback }) => {
    if (!feedback) {
        return <div>Loading...</div>;
    }

    const difficultyToClass = {
        'easy': 'star green',
        'medium': 'star yellow',
        'hard': 'star red'
    };

    return (
        <div className="exercise-difficulty-feedback">
            <h3 className="title">EXERCISE DIFFICULTY FEEDBACK</h3>
            <div className="feedback-stars">
                {feedback.map((feedbackForDay, index) => (
                    <div key={index} className="daily-feedback">
                        <span className={difficultyToClass[feedbackForDay.difficulty] || 'star'}>
                            &#9733; {/* Unicode character for a star */}
                        </span>
                        <span className="day-indicator">{feedbackForDay.day}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExerciseDifficultyFeedback;

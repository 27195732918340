import React, { useState } from "react";
import PatientList from "./PatientListComponent";
import PatientProgressOverview from "./PatientProgressOverview";
import "./Dashboard.css";
import logo from "./RecovR.png";

const Dashboard = () => {
  // State to keep track of the selected patient
  const [selectedPatient, setSelectedPatient] = useState(null);

  // Handlers for the button clicks
  const handlePatientListClick = () => {
    // Logic to show patient list
  };

  const handleAddNewPatientClick = () => {
    // Logic to add a new patient
  };

  // More handlers for other buttons...

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="logo-container">
          <img src={logo} alt="Business Logo" className="business-logo" />
          <h1 className="business-name">RecovR</h1>
        </div>
      </header>
      <nav className="navigation-buttons">
        <button onClick={handlePatientListClick}>Patient List</button>
        <button>Exercise List</button>
        <button>Update Exercise</button>
        <button onClick={handleAddNewPatientClick}>Add New Patient</button>
        <button onClick={handleAddNewPatientClick}>Give Feedback</button>
        <button>Add New Exercise</button>
      </nav>
      <div className="main-content">
        <PatientList setSelectedPatient={setSelectedPatient} />
        {selectedPatient ? (
          <PatientProgressOverview patient={selectedPatient} />
        ) : (
          <p className="select-patient-message">
            Select one of your patients from the list.
          </p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

import React from 'react';
import './ActivityTime.css'; // Make sure to create and import a corresponding CSS file

const ActivityTime = ({ times }) => {
    if (!times) {
        return <div>Loading...</div>;
    }

    return (
        <div className="activity-time">
            <h3 className="title">ACTIVITY TIME</h3>
                <p>Current Week: {times.currentWeek} mins</p>
                <p>Overall: {times.overall} mins</p>
                <div className="pie-chart-with-legend">
                    <div className="pie-chart-placeholder"></div>
                        <div className="chart-legend">
                        <div className="legend-item">
                <span className="legend-color-box missed"></span>Missed
            </div>
            <div className="legend-item">
                <span className="legend-color-box incomplete"></span>Incomplete
            </div>
            <div className="legend-item">
                <span className="legend-color-box completed"></span>Completed
            </div>
            </div>
        </div>
        </div>
    );
};

export default ActivityTime;
